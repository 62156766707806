* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.checkOutPage {
  font-family: "Poppins", sans-serif;
  height: 100vh;
  width: 70%;
  margin: 0px auto;
  padding: 50px 0px 0px;
  color: #4e5150;
}
.checkOutPage header {
  height: 5%;
  margin-bottom: 30px;
}
.checkOutPage header > h3 {
  font-size: 25px;
  color: #4e5150;
  font-weight: 500;
}
.checkOutPage main {
  height: 85%;
  display: flex;
  column-gap: 100px;
}
.checkOutPage main .checkout-form {
  width: 50%;
}
.checkOutPage main .checkout-form form h6 {
  font-size: 12px;
  font-weight: 500;
}
.checkOutPage main .checkout-form form .form-control-check {
  margin: 10px 0px;
  position: relative;
}
.checkOutPage
  main
  .checkout-form
  form
  .form-control-check
  label:not([for="checkout-checkbox"]) {
  display: block;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 2px;
}
.checkOutPage
  main
  .checkout-form
  form
  .form-control-check
  input:not([type="checkbox"]) {
  width: 100%;
  padding: 10px 10px 10px 40px;
  border-radius: 10px;
  outline: none;
  border: 0.2px solid #4e5150 85;
  font-size: 12px;
  font-weight: 700;
}
.checkOutPage
  main
  .checkout-form
  form
  .form-control-check
  input:not([type="checkbox"])::placeholder {
  font-size: 10px;
  font-weight: 500;
}
.checkOutPage
  main
  .checkout-form
  form
  .form-control-check
  label[for="checkout-checkbox"] {
  font-size: 9px;
  font-weight: 500;
  line-height: 10px;
}
.checkOutPage main .checkout-form form .form-control-check > div {
  position: relative;
}
.checkOutPage main .checkout-form form .form-control-check > div span.fa {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(15px, -50%);
}
.checkOutPage main .checkout-form form .form-group {
  display: flex;
  column-gap: 25px;
}
.checkOutPage main .checkout-form form .checkbox-control {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.checkOutPage main .checkout-form form .form-control-check-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.checkOutPage main .checkout-form form .form-control-check-btn button {
  padding: 10px 25px;
  font-size: 10px;
  color: #fff;
  background: #f2994a;
  border: 0;
  border-radius: 7px;
  letter-spacing: 0.5px;
  font-weight: 200;
  cursor: pointer;
}
.checkOutPage main .checkout-details {
  width: 40%;
}
.checkOutPage main .checkout-details .checkout-details-inner {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
}
.checkOutPage main .checkout-details .checkout-details-inner .checkout-lists {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 40px;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card {
  width: 100%;
  display: flex;
  column-gap: 15px;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-image {
  width: 35%;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-image
  img {
  width: 100%;
  object-fit: fill;
  border-radius: 10px;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-details {
  display: flex;
  flex-direction: column;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-details
  .card-name {
  font-size: 12px;
  font-weight: 500;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-details
  .card-price {
  font-size: 10px;
  font-weight: 500;
  color: #f2994a;
  margin-top: 5px;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-details
  .card-price
  span {
  color: #4e5150;
  text-decoration: line-through;
  margin-left: 10px;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-details
  .card-wheel {
  margin-top: 17px;
  border: 0.2px solid #4e5150 85;
  width: 90px;
  padding: 8px 8px;
  border-radius: 10px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-details
  .card-wheel
  button {
  background: #e0e0e0;
  color: #828282;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 500;
}
.checkOutPage main .checkout-details .checkout-details-inner .checkout-shipping,
.checkOutPage main .checkout-details .checkout-details-inner .checkout-total {
  display: flex;
  font-size: 16px;
  padding: 5px 0px;
  border-top: 1px solid #bdbdbd;
  justify-content: space-between;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-shipping
  p,
.checkOutPage main .checkout-details .checkout-details-inner .checkout-total p {
  font-size: 10px;
  font-weight: 500;
}
.checkOutPage footer {
  height: 5%;
  color: #bdbdbd;
  display: -ms-grid;
  display: grid;
  place-items: center;
  font-size: 12px;
}
.checkOutPage footer a {
  text-decoration: none;
  color: inherit;
}
@media screen and (max-width: 1024px) {
  .checkOutPage {
    width: 80%;
  }
  .checkOutPage main {
    column-gap: 70px;
  }
}
@media screen and (max-width: 768px) {
  .checkOutPage {
    width: 92%;
  }
  .checkOutPage main {
    flex-direction: column-reverse;
    height: auto;
    margin-bottom: 50px;
  }
  .checkOutPage main .checkout-form {
    width: 100%;
    margin-top: 35px;
  }
  .checkOutPage main .checkout-details {
    width: 100%;
  }
  .checkOutPage footer {
    height: 10%;
  }
}




.thankyou-wrapper{
  width:100%;
  height:auto;
  margin:auto;
  /* background:#ffffff;  */
  padding:10px 0px 50px;
}
.thankyou-wrapper h1{
  font:100px Arial, Helvetica, sans-serif;
  text-align:center;
  color:#333333;
  padding:0px 10px 10px;
}
.thankyou-wrapper p{
  font:26px Arial, Helvetica, sans-serif;
  text-align:center;
  color:#333333;
  padding:5px 10px 10px;
}
.thankyou-wrapper a{
  font:26px Arial, Helvetica, sans-serif;
  text-align:center;
  color:#ffffff;
  display:block;
  text-decoration:none;
  width:250px;
  background:#E47425;
  margin:10px auto 0px;
  padding:15px 20px 15px;
  border-bottom:5px solid #F96700;
}
.thankyou-wrapper a:hover{
  font:26px Arial, Helvetica, sans-serif;
  text-align:center;
  color:#ffffff;
  display:block;
  text-decoration:none;
  width:250px;
  background:#F96700;
  margin:10px auto 0px;
  padding:15px 20px 15px;
  border-bottom:5px solid #F96700;
}

.ProductDetails {
  /* background-color: rgb(255, 255, 255); */
  width: 100vw;
  max-width: 100%;
  padding: 4vmax 6vmax 6vmax;
  box-sizing: border-box;
  display: flex;
}

.ProductDetails > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 2vmax;
  box-sizing: border-box;
  border: 1px solid white;
}

.ProductDetails > div:last-child {
  align-items: flex-start;
}

.CarouselImage {
  border-radius: 14px;
}

.detailsBlock-1 > h2 {
  color: rgb(54, 54, 54);
  font: 600 3.6vmax ;
}

.detailsBlock-1 > p {
  color: rgba(54, 54, 54, 0.582);
  font: 200 0.6vmax cursive;
}

.detailsBlock-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.205);
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  width: 70%;
  padding: 1vmax 0;
}

.detailsBlock-2-span {
  font: 200 0.8vmax cursive;
  color: rgba(0, 0, 0, 0.699);
}

.detailsBlock-3 {
  width: 70%;
}

.detailsBlock-3 > h1 {
  color: rgba(17, 17, 17, 0.795);
  font: 400 1.8vmax ;
  margin: 1vmax 0;
}
.detailsBlock-3-1 {
  display: flex;
  align-items: center;
}

.detailsBlock-3-1-1 > button {
  border: none;
  background-color: rgba(0, 0, 0, 0.616);
  padding: 0.5vmax;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
}
.detailsBlock-3-1-1 > button:hover {
  background-color: rgba(0, 0, 0, 0.767);
}

.detailsBlock-3-1-1 > input {
  border: none;
  padding: 0.5vmax;
  width: 2vmax;
  text-align: center;
  outline: none;
  font: 400 0.8vmax ;

  color: rgba(0, 0, 0, 0.74);
}

.detailsBlock-3-1 > button:last-child {
  border: none;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
  background-color: #996253;
  font: 500 1.2vmax ;
  border-radius: 20px;
  padding: 0.5vmax 2vmax;
  margin: 1vmax;
  outline: none;
}

.detailsBlock-3-1 > button:last-child:hover {
  background-color: rgb(214, 84, 61);
}

.detailsBlock-3 > p {
  border-top: 1px solid rgba(0, 0, 0, 0.205);
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  padding: 1vmax 0;
  color: rgba(0, 0, 0, 0.651);
  font: 400 1.2vmax ;
  margin: 1vmax 0;
}

.detailsBlock-4 {
  color: rgba(0, 0, 0, 0.897);
  font: 700 20px ;
}

.detailsBlock-4 > p {
  color: rgba(0, 0, 0, 0.864);
  font: 300 16px ;
}
.image-zoom > img {
  aspect-ratio: 11 / 11;
    object-fit: contain;
}
.submitReview {
  border: none;
  background-color: #996253;
  font: 600 0.99vmax ;
  border-radius: 20px;
  padding: 0.6vmax 2vmax;
  margin: 1vmax 0;
  color: white;
  cursor: pointer;
  transition: all 0.5s;
  outline: none;
}
.submitReview:hover {
  background-color: rgb(197, 68, 45);
  transform: scale(1.1);
}

.submitDialog {
  display: flex;
  flex-direction: column;
}
.submitDialogTextArea {
  border: 1px solid rgba(0, 0, 0, 0.082);
  margin: 1vmax 0;
  outline: none;
  padding: 1rem;
  font: 300 1rem ;
}

.reviewsHeading {
  color: #000000be;
  font: 500 1.4vmax ;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
  padding: 1vmax;
  width: 20vmax;
  margin: auto;
  margin-bottom: 4vmax;
}
.reviews {
  display: flex;
  overflow: auto;
}

.reviewCard {
  flex: none;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.226);
  border: 1px solid rgba(56, 56, 56, 0.116);
  width: 30vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vmax;
  padding: 3vmax;
}
.reviewCard > img {
  width: 5vmax;
}
.reviewCard > p {
  color: rgba(0, 0, 0, 0.836);
  font: 600 0.9vmax ;
}
.reviewCardComment {
  color: rgba(0, 0, 0, 0.445);
  font: 300 0.8vmax cursive;
}

.noReviews {
  font: 400 1.3vmax ;
  text-align: center;
  color: rgba(0, 0, 0, 0.548);
}

.back-btn {
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
  margin-top: 3vmax;
}
.back-btn > button {
  display: flex;
  height: 3em;
  width: 100px;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee4b;
  border-radius: 3px;
  letter-spacing: 1px;
  transition: all 0.2s linear;
  cursor: pointer;
  background: transparent;
  border: 1px solid black;
}

.back-btn > button > svg {
  margin-right: 5px;
  margin-left: 5px;
  font-size: 20px;
  transition: all 0.4s ease-in;
}

.back-btn > button:hover > svg {
  font-size: 1.2em;
  transform: translateX(-5px);
}

.back-btn > button:hover {
  box-shadow: 9px 9px 33px #d1d1d1, -9px -9px 33px #ffffff;
  transform: translateY(-2px);
}


.thumbs-wrapper > ul {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .ProductDetails {
    flex-direction: column;
    height: unset;
  }

  .ProductDetails > div:last-child {
    align-items: center;
  }

  .detailsBlock-1 > h2 {
    font-size: 2.8vmax;
    text-align: center;
  }

  .detailsBlock-1 > p {
    text-align: center;
    font-size: 1vmax;
  }

  .detailsBlock-2 {
    justify-content: center;
  }
  .detailsBlock-2 > span {
    font-size: 1.5vmax;
  }

  .detailsBlock-3 > h1 {
    font: 700 3vmax ;
    text-align: center;
  }

  .detailsBlock-3-1 {
    flex-direction: column;
  }

  .detailsBlock-3-1-1 {
    padding: 2vmax 0;
  }
  .detailsBlock-3-1-1 > button {
    padding: 1.2vmax;
    width: 4vmax;
  }

  .detailsBlock-3-1-1 > input {
    padding: 1.5vmax;
    width: 3vmax;
    font: 400 1.8vmax ;
  }

  .detailsBlock-3-1 > button:last-child {
    font: 500 1.7vmax ;
    padding: 1.5vmax;
    width: 20vmax;
    margin: 3vmax 0;
  }

  .detailsBlock-3 > p {
    padding: 2.5vmax 0;
    text-align: center;
    font: 400 2vmax ;
  }

  .detailsBlock-4 {
    font: 500 2.5vmax ;
  }

  .detailsBlock-4 > p {
    font: 300 1.8vmax ;
  }

  .submitReview {
    font: 500 1.7vmax ;
    padding: 1.5vmax;
    width: 20vmax;
    margin: 3vmax 0;
  }

  .reviewCard > p {
    font: 600 3vw ;
  }
  .reviewCardComment {
    font: 300 5vw cursive;
  }
}
.carousel-container {
  position: relative;
  z-index: 1; /* Ensure the container is on the correct layer */
}


.product-view-image {
  max-width: 300px;
  margin: 25px;
}

.product-view-image img {
  max-width: 100%;
}

.loupe {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px solid black;
  box-shadow: 5px 5px 12px black;
  background: rgba(0, 0, 0, 0.25);
  cursor: crosshair;
  overflow: hidden;
  display: none;
}

.loupe img {
  position: absolute;
}
.about-us-content {
  font-size: 20px;
  padding: 30px;
  text-transform: capitalize;
  margin: 0px 60px 120px 60px;
}
.about-us-image {
  width: 100%;
  height: 700px;
  object-fit: fill;
  background-position: center;
}

/* flip card Css */

.flip-card {
  background-color: transparent;
  width: 230px;
  height: 314px;
  perspective: 1000px;
  
}

.title {
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  margin: 0;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid #e0bd97;
  padding: 10px;
  border-radius: 1rem;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(190, 190, 190),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 10px;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.flip-card-back {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid coral;
  border-radius: 1rem;
  padding: 10px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(190, 190, 190),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 10px;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.flip-card-front {
  color: black;
  background: #e0bd97;
}

.flip-card-back {
  background: linear-gradient(
    120deg,
    bisque 60%,
    rgb(255, 231, 222) 88%,
    rgb(255, 211, 195) 40%,
    rgba(255, 127, 80, 0.603) 48%
  );
  color: black;
  transform: rotateY(180deg);
}

/* product View Css  */

div#container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  padding: 50px;
  padding-bottom: 100px;
}

.flip-card-front > img {
  padding: 1px;
  object-fit: contain;
  height: 221px;
  width: 210px;
  border-radius: 14px;
}

.flip-card-back,
.flip-card-front > p {
  font-size: x-large;
  font-weight: 500;
  margin-bottom: 0.5rem !important;
}

.flip-card-back-rating-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.flip-card-back-price-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.flip-card-back-btn-div {
  padding-top: 0px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

/* Quick View btn css  */

.quick-view-btn {
  position: relative;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  border-radius: 50px;
  background-color: #996253;
  border: solid 4px #e3b373;
  font-family: inherit;
  margin: 10px;
}

.default-btn,
.hover-btn {
  background-color: #996253;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px 5px;
  border-radius: 50px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.hover-btn {
  position: absolute;
  inset: 0;
  background-color: #996253;
  transform: translate(0%, 100%);
  padding-left: 10px;
}

.default-btn span {
  color: #fff;
}

.hover-btn span {
  color: #fff;
}

.quick-view-btn:hover .default-btn {
  transform: translate(0%, -100%);
}

.quick-view-btn:hover .hover-btn {
  transform: translate(0%, 0%);
}

/* Add to Cart Btn Css */

button.add-to-cart-btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-family: inherit;
  font-size: 10px;
  margin: 5px 10px 10px;
}

button.add-to-cart-btn {
  font-weight: 600;
  color: #382b22;
  text-transform: uppercase;
  padding: 1.25em 2em;
  background: #fff0f0;
  border: 2px solid #b18597;
  border-radius: 0.75em;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: background 150ms cubic-bezier(0, 0, 0.58, 1),
    -webkit-transform 150ms cubic-bezier(0, 0, 0.58, 1);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    background 150ms cubic-bezier(0, 0, 0.58, 1),
    -webkit-transform 150ms cubic-bezier(0, 0, 0.58, 1);
}

button.add-to-cart-btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f9c4d2;
  border-radius: inherit;
  -webkit-box-shadow: 0 0 0 2px #b18597, 0 0.625em 0 0 #ffe3e2;
  box-shadow: 0 0 0 2px #b18597, 0 0.625em 0 0 #ffe3e2;
  -webkit-transform: translate3d(0, 0.75em, -1em);
  transform: translate3d(0, 0.75em, -1em);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    box-shadow 150ms cubic-bezier(0, 0, 0.58, 1),
    -webkit-transform 150ms cubic-bezier(0, 0, 0.58, 1),
    -webkit-box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}

button.add-to-cart-btn:hover {
  background: #ffe9e9;
  -webkit-transform: translate(0, 0.25em);
  transform: translate(0, 0.25em);
}

button.add-to-cart-btn:hover::before {
  -webkit-box-shadow: 0 0 0 2px #b18597, 0 0.5em 0 0 #ffe3e2;
  box-shadow: 0 0 0 2px #b18597, 0 0.5em 0 0 #ffe3e2;
  -webkit-transform: translate3d(0, 0.5em, -1em);
  transform: translate3d(0, 0.5em, -1em);
}

button.add-to-cart-btn:active {
  background: #ffe9e9;
  -webkit-transform: translate(0em, 0.75em);
  transform: translate(0em, 0.75em);
}

button.add-to-cart-btn:active::before {
  -webkit-box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2;
  box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2;
  -webkit-transform: translate3d(0, 0, -1em);
  transform: translate3d(0, 0, -1em);
}

.wrapper > h1 {
  font-size: 80px;
  margin-bottom: 0;
  line-height: 1;
  font-weight: 700;
  color: #382b22;
}

.dot {
  border-radius: 60px;
  border: brown 5px solid;
  height: 5px;
  display: inline-block;
}

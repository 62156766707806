@import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Unna:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Unna:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}


.jost-font {
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.unna-regular {
  font-family: "Unna", serif;
  font-weight: 400;
  font-style: normal;
}




body {
  padding: 0px;
  font-family: "Unna", serif !important;
  margin: 0px;
  background-image: url(/static/media/backgroundImage.d654b7cb.jpg) !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(227, 179, 115);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(135, 96, 46);
}

.pg-footer {
  /* font-family: "Roboto", sans-serif; */
}

.footer {
  background-color: rgb(223 191 137);
  color: black;
  /* font-family: Marcellus; */
}

.footer-wave-svg {
  background-color: transparent;
  display: block;
  height: 30px;
  position: relative;
  top: -1px;
  width: 100%;
}

.footer-wave-path {
  fill: antiquewhite;
}

.footer-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  padding: 40px 15px 332px;
  position: relative;
}

.footer-content-column {
  box-sizing: border-box;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  color: black;
}

.footer-content-column ul li a {
  color: black;
  text-decoration: none;
}

.footer-logo,
.footer-logo-link {
  color: antiquewhite !important;
  font-size: 24px;
}
.footer-logo-link {
  display: inline-block;
}

.footer-menu {
  margin-top: 30px;
}

.footer-menu-name {
  color: black;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

.footer-menu-list {
  list-style: none;
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 0;
}

.footer-menu-list li {
  margin-top: 5px;
}

.footer-call-to-action-description {
  color: black;
  margin-top: 10px;
  margin-bottom: 20px;
}

.footer-call-to-action-button:hover {
  background-color: #fffff2;
  color: #00bef0;
}

.button:last-of-type {
  margin-right: 0;
}

.footer-call-to-action-button {
  background-color: #996253;
  border-radius: 21px;
  color: white;
  display: inline-block;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 18px;
  padding: 12px 30px;
  margin: 0 10px 10px 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.2s;
  cursor: pointer;
  position: relative;
}

.footer-call-to-action {
  margin-top: 30px;
}

.footer-call-to-action-title {
  color: black;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

.footer-call-to-action-link-wrapper {
  margin-bottom: 0;
  margin-top: 10px;
  color: #fff;
  text-decoration: none;
}

.footer-call-to-action-link-wrapper a {
  color: #fff;
  text-decoration: none;
}

.footer-social-links {
  bottom: 0;
  height: 54px;
  position: absolute;
  right: 0;
  width: 236px;
}

.footer-social-amoeba-svg {
  height: 54px;
  left: 0;
  display: block;
  position: absolute;
  top: 0;
  width: 236px;
}

.footer-social-amoeba-path {
  fill: #996253;
}

.footer-social-link.linkedin {
  left: 3px;
  top: 11px;
}

.footer-social-link.linkedin > svg {
  height: 26px;
  width: 26px;
}

.footer-social-link {
  display: block;
  padding: 10px;
  position: absolute;
}

.hidden-link-text {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  top: 50%;
}

.footer-social-icon-svg {
  display: block;
}

.footer-social-icon-path {
  fill: #fffff2;
  transition: fill 0.2s;
}

.footer-social-link.twitter {
  left: 62px;
  top: 3px;
}
.footer-social-link.twitter > svg {
  height: 28px;
  width: 28px;
}

.footer-social-link.youtube {
  left: 123px;
  top: 12px;
}
.footer-social-link.youtube > svg {
  height: 24px;
  width: 24px;
}

.footer-social-link.github {
  left: 172px;
  top: 7px;
}
.footer-social-link.github > svg {
  height: 34px;
  width: 34px;
}

.footer-copyright {
  background-color: black;
  color: #fff;
  padding: 15px 30px;
  text-align: center;
}

.footer-copyright-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.footer-copyright-text {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

.footer-copyright-link {
  color: #fff;
  text-decoration: none;
}

/* Media Query For different screens */
@media (min-width: 320px) and (max-width: 479px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}

@media (min-width: 480px) and (max-width: 599px) {
  /* smartphones, Android phones, landscape iPhone */
  .footer-content {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */

  .footer-content {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 760px) {
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 450px;
    position: relative;
  }

  .footer-wave-svg {
    height: 50px;
  }

  .footer-content-column {
    width: 24.99%;
  }
}

@media (min-width: 568px) {
  /* .footer-content-column {
      width: 49.99%;
  } */
}

@media (min-width: 200px) and (max-width: 479px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */

  .footer-content {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}

/* Header Css */

.navbar {
  padding: 10px;
  background-color: antiquewhite;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
  font-size: 15px;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 600;
  margin-left: 10px;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link,
.des_filter ul li a:hover {
  color: white;
  background: #996253;
}
.custom-login:hover {
  color: white;
  background: #996253;
}
.custom-login {
  color: black;
  font-size: 15px;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 600;
  text-decoration: none !important;
  margin-left: 10px;
}

#navbarSupportedContent .dropdown:hover > .dropdown-menu,
#navbarSupportedContent .dropend:hover > .dropdown-menu {
  display: block;
  margin-top: 0.125em;
  margin-left: 0.125em;
}

#navbarSupportedContent .dropend .dropdown-toggle::after {
  margin-left: 0.5em;
  font-size: 19px;
  transform: rotate(90deg);
  transition: transform 0.2s;
}

#navbarSupportedContent .dropend .dropdown-toggle:hover::after {
  transform: rotate(-90deg);
}

#navbarSupportedContent .dropend ul.dropdown-menu,
#navbarSupportedContent .dropend:hover > .dropdown-menu {
  padding: 5px;
  margin-left: 9px;
  border: none;
  box-shadow: 0 4px 6px rgb(128, 128, 128) !important;
}

#navbarSupportedContent .dropend ul.dropdown-menu li {
  border-bottom: 1px solid rgb(223 220 220);
}

#navbarSupportedContent .dropend ul.dropdown-menu li:last-child {
  border-bottom: unset;
}

#navbarSupportedContent .dropend ul.dropdown-menu li .dropdown-item {
  border-radius: 5px;
  margin: 5px 0;
}

#navbarSupportedContent
  .dropend
  ul.dropdown-menu
  li:first-child
  .dropdown-item {
  margin: 0 0 5px 0;
}

#navbarSupportedContent .dropend ul.dropdown-menu li:last-child .dropdown-item {
  /* margin: 5px 0 0 0; */
}

#navbarSupportedContent .dropend ul.dropdown-menu li .dropdown-item:hover,
#navbarSupportedContent .dropend ul.dropdown-menu li .dropdown-item.active {
  background-color: #172145;
  color: #ffffff;
}

#navbarSupportedContent .navbar-nav li:last-child {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: left; */
  /* align-self: unset !important; */
}

#navbarSupportedContent .logout-btn {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* box-shadow: 0 4px 6px rgb(255 255 255) !important; */
}

#navbarSupportedContent .logout-btn:hover {
  box-shadow: none !important;
}

#navbarSupportedContent .profile-pic {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  margin-left: 13px;
  margin-right: 4px;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.badge {
  background-color: #996253;
  color: white;
  font-size: 8px;
  border-radius: 20px;
  height: 13px;
  position: relative;
  top: -15px;
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 992px) {
  #navbarSupportedContent .navbar-nav li:last-child {
    /* margin-left: 12px;
    margin-top: 5px; */
  }

  #navbarSupportedContent .navbar-nav .dropdown-menu li:last-child {
    margin-left: unset;
  }

  #navbarSupportedContent .dropend ul.dropdown-menu,
  #navbarSupportedContent .dropend:hover > .dropdown-menu {
    box-shadow: unset !important;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-active {
  color: white !important;
  background: #996253 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #996253;
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .dropbtn {
  color: white;
  background: #996253;
}

.main-loader {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
@keyframes wave {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-15px);
  }
}
.loader ul {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 60px;
  text-align: center;
  animation: wave 2.5s infinite linear;
  animation-fill-mode: forwards;
}
.loader li {
  display: inline-block;
  background: rgb(228, 132, 6);
  height: 30px;
  width: 7px;
  border-radius: 0 100%;
  transform: rotate(12deg);
}

.layout_div {
  background-image: url(/static/media/backgroundImage.d654b7cb.jpg) !important;
}

.outLet_div {
  padding-top: 85px;
}


.home_div {
  padding-bottom: 120px;
}


.unna-regular {
  font-family: "Unna", serif;
  font-weight: 400;
  font-style: normal;
}


.heading_div {
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heading_div > h1 {
  width: 100px;
  text-align: center;
  z-index: 10;
  /* font-family: Marcellus; */
  text-transform: uppercase;
  /* height: auto; */
  width: 100%;
  color: rgb(64, 64, 64);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  line-height: 70px;
  letter-spacing: 5px;
  font-weight: 300;
  font-size: 25px;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}

.LandingLogo5 {
  margin-left: 10px;
}

.custom-home-div {
  margin: 0px !important;
  padding: 0px !important;
  flex-grow: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LandingLogo3 {
  max-width: 342px;
  margin-top: 43px;
}
.custom_card {
  height: 370px;

  width: 18rem !important;
  border-radius: 20px !important;
  padding: 25px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);

  background-color: rgb(223 191 137);
}

.custom_card > h2 {
  /* font-family: Marcellus; */
  text-align: center;
}

.custom_coffee_machine {
  /* font-family: Marcellus; */
  text-align: center;
}

.custom_coffee_machine > h1 {
  width: 100px;
  text-align: center;
  z-index: 10;
  /* font-family: Marcellus; */
  text-transform: uppercase;
  /* height: auto; */
  width: 100%;
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  line-height: 70px;
  letter-spacing: 5px;
  font-weight: 300;
  font-size: 40px;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}
.custom_menu_machine > h1 {
  width: 100px;
  text-align: center;
  z-index: 10;
  /* font-family: Marcellus; */
  text-transform: uppercase;
  /* height: auto; */
  width: 100%;
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  line-height: 70px;
  letter-spacing: 5px;
  font-weight: 300;
  font-size: 50px;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}

.card-img-top {
  border-radius: 20px;
  object-fit: cover;
  margin-top: 5px;
  height: 278px;
  box-shadow: 4px 4px 3px 1px rgba(0, 0, 0, 0.44);
}

.img-cen {
  justify-content: center;
  display: flex;
}

.card.custom_card:hover {
  transform: scale(1.1);
  transition: 0.5s all;
}

.card.custom_card {
  box-shadow: 12px 12px 3px 1px rgba(0, 0, 0, 0.44);
}

.learn-more {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

.learn-more {
  width: 12rem;
  height: auto;
}

.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
}

.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

.learn-more:hover .circle {
  width: 100%;
}

.learn-more:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

.learn-more:hover .button-text {
  color: #fff;
}

.cta {
  border: none !important;
  background: none;
}

.cta span {
  padding-bottom: 7px;
  letter-spacing: 4px;
  font-size: 14px;
  padding-right: 15px;
  text-transform: uppercase;
  cursor: pointer;
}

.cta svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active svg {
  transform: scale(0.9);
}

.hover-underline-animation {
  position: relative;
  color: black;
  padding-bottom: 20px;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.cta:hover .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.view-more-btn:hover::after {
  width: 100%;
}

.view-more-btn:hover p::before {
  width: 100%;
}

.view-more-btn:hover svg {
  transform: translateX(4px);
  color: var(--hovered-color);
}

.view-more-btn svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
}

.animate-ex {
}

@media screen and (max-width: 700px) {
  .heading_div > h1 {
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  .heading_div > h1 {
    font-size: 12px;
  }
}
@media screen and (max-width: 600px) {
  .custom_menu_machine > h1 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (min-width: 200px) and (max-width: 359px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */

  .card-text {
    font-size: 12px;
  }
  .LandingLogo5 {
    width: 180px;
    height: 180px;
  }
}

@media (min-width: 200px) and (max-width: 350px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */

  .custom_card {
    width: 12rem !important;
  }

  .img-cen > img {
    width: 200px;
    height: 200px;
  }

  .LandingLogo5 {
    width: 200px;
    height: 200px;
  }
  .LandingLogo3 {
    width: 200px;
    height: 200px;
  }

  .heading_div > h1 {
    font-size: 8px;
  }
}

.search-box {
  padding: 50px auto;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 30px;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.form-control {
  border-radius: 20px !important;
  background-color: transparent;
  border-color: black;
}

.form-group {
  margin: 0px !important;
  padding-bottom: 1rem;
}

.custom-menu-col > button {
  cursor: pointer;
  width: 150px;
  margin: 10px;
  font-size: 18px;
  background-color: transparent;
  border-radius: 12px;
  color: rgb(5, 1, 1);
}

.custom-menu-col > button:hover {
  background-color: black;
  color: white;
  transition: 0.5s all;
}

.activeState > button {
  color: white;
  background-color: tomato;
}
.searchbar {
  font-size: 14px;
  font-family: arial, sans-serif;
  color: #202124;
  display: flex;
  z-index: 3;
  height: 44px;
  background: transparent;
  border: 1px solid black;
  box-shadow: none;
  border-radius: 24px;
  margin: 0 auto;
  width: auto;
  max-width: 224px;
}

.searchbar:hover {
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  border-color: rgba(223, 225, 229, 0);
}

.searchbar-wrapper {
  flex: 1 1;
  display: flex;
  padding: 5px 8px 0 14px;
}

.searchbar-left {
  font-size: 14px;
  font-family: arial, sans-serif;
  color: #202124;
  display: flex;
  align-items: center;
  padding-right: 13px;
  margin-top: -5px;
}

.search-icon-wrapper {
  margin: auto;
}

.search-icon {
  margin-top: 3px;
  color: #9aa0a6;
  height: 20px;
  line-height: 20px;
  width: 20px;
}

.searchbar-icon {
  display: inline-block;
  fill: currentColor;
  height: 24px;
  line-height: 24px;
  position: relative;
  width: 24px;
}

.searchbar-center {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
}

.searchbar-input-spacer {
  color: transparent;
  flex: 100% 1;
  white-space: pre;
  height: 34px;
  font-size: 16px;
}

.searchbar-input {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  outline: none;
  display: flex;
  flex: 100% 1;
  margin-top: -37px;
  height: 34px;
  font-size: 16px;
  max-width: 100%;
  width: 100%;
}

/* flip card Css */

.flip-card {
  background-color: transparent;
  width: 230px;
  height: 314px;
  perspective: 1000px;
  
}

.title {
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  margin: 0;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border: 1px solid #e0bd97;
  padding: 10px;
  border-radius: 1rem;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(190, 190, 190),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 10px;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.flip-card-back {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border: 1px solid coral;
  border-radius: 1rem;
  padding: 10px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(190, 190, 190),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 10px;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.flip-card-front {
  color: black;
  background: #e0bd97;
}

.flip-card-back {
  background: linear-gradient(
    120deg,
    bisque 60%,
    rgb(255, 231, 222) 88%,
    rgb(255, 211, 195) 40%,
    rgba(255, 127, 80, 0.603) 48%
  );
  color: black;
  transform: rotateY(180deg);
}

/* product View Css  */

div#container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 50px;
  gap: 50px;
  padding: 50px;
  padding-bottom: 100px;
}

.flip-card-front > img {
  padding: 1px;
  object-fit: contain;
  height: 221px;
  width: 210px;
  border-radius: 14px;
}

.flip-card-back,
.flip-card-front > p {
  font-size: x-large;
  font-weight: 500;
  margin-bottom: 0.5rem !important;
}

.flip-card-back-rating-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.flip-card-back-price-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.flip-card-back-btn-div {
  padding-top: 0px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

/* Quick View btn css  */

.quick-view-btn {
  position: relative;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  border-radius: 50px;
  background-color: #996253;
  border: solid 4px #e3b373;
  font-family: inherit;
  margin: 10px;
}

.default-btn,
.hover-btn {
  background-color: #996253;
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  padding: 5px 5px;
  border-radius: 50px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.hover-btn {
  position: absolute;
  inset: 0;
  background-color: #996253;
  transform: translate(0%, 100%);
  padding-left: 10px;
}

.default-btn span {
  color: #fff;
}

.hover-btn span {
  color: #fff;
}

.quick-view-btn:hover .default-btn {
  transform: translate(0%, -100%);
}

.quick-view-btn:hover .hover-btn {
  transform: translate(0%, 0%);
}

/* Add to Cart Btn Css */

button.add-to-cart-btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-family: inherit;
  font-size: 10px;
  margin: 5px 10px 10px;
}

button.add-to-cart-btn {
  font-weight: 600;
  color: #382b22;
  text-transform: uppercase;
  padding: 1.25em 2em;
  background: #fff0f0;
  border: 2px solid #b18597;
  border-radius: 0.75em;
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    background 150ms cubic-bezier(0, 0, 0.58, 1);
}

button.add-to-cart-btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f9c4d2;
  border-radius: inherit;
  box-shadow: 0 0 0 2px #b18597, 0 0.625em 0 0 #ffe3e2;
  transform: translate3d(0, 0.75em, -1em);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}

button.add-to-cart-btn:hover {
  background: #ffe9e9;
  transform: translate(0, 0.25em);
}

button.add-to-cart-btn:hover::before {
  box-shadow: 0 0 0 2px #b18597, 0 0.5em 0 0 #ffe3e2;
  transform: translate3d(0, 0.5em, -1em);
}

button.add-to-cart-btn:active {
  background: #ffe9e9;
  transform: translate(0em, 0.75em);
}

button.add-to-cart-btn:active::before {
  box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2;
  transform: translate3d(0, 0, -1em);
}

.wrapper > h1 {
  font-size: 80px;
  margin-bottom: 0;
  line-height: 1;
  font-weight: 700;
  color: #382b22;
}

.dot {
  border-radius: 60px;
  border: brown 5px solid;
  height: 5px;
  display: inline-block;
}

.ProductDetails {
  /* background-color: rgb(255, 255, 255); */
  width: 100vw;
  max-width: 100%;
  padding: 4vmax 6vmax 6vmax;
  box-sizing: border-box;
  display: flex;
}

.ProductDetails > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 2vmax;
  box-sizing: border-box;
  border: 1px solid white;
}

.ProductDetails > div:last-child {
  align-items: flex-start;
}

.CarouselImage {
  border-radius: 14px;
}

.detailsBlock-1 > h2 {
  color: rgb(54, 54, 54);
  font: 600 3.6vmax ;
}

.detailsBlock-1 > p {
  color: rgba(54, 54, 54, 0.582);
  font: 200 0.6vmax cursive;
}

.detailsBlock-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.205);
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  width: 70%;
  padding: 1vmax 0;
}

.detailsBlock-2-span {
  font: 200 0.8vmax cursive;
  color: rgba(0, 0, 0, 0.699);
}

.detailsBlock-3 {
  width: 70%;
}

.detailsBlock-3 > h1 {
  color: rgba(17, 17, 17, 0.795);
  font: 400 1.8vmax ;
  margin: 1vmax 0;
}
.detailsBlock-3-1 {
  display: flex;
  align-items: center;
}

.detailsBlock-3-1-1 > button {
  border: none;
  background-color: rgba(0, 0, 0, 0.616);
  padding: 0.5vmax;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
}
.detailsBlock-3-1-1 > button:hover {
  background-color: rgba(0, 0, 0, 0.767);
}

.detailsBlock-3-1-1 > input {
  border: none;
  padding: 0.5vmax;
  width: 2vmax;
  text-align: center;
  outline: none;
  font: 400 0.8vmax ;

  color: rgba(0, 0, 0, 0.74);
}

.detailsBlock-3-1 > button:last-child {
  border: none;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
  background-color: #996253;
  font: 500 1.2vmax ;
  border-radius: 20px;
  padding: 0.5vmax 2vmax;
  margin: 1vmax;
  outline: none;
}

.detailsBlock-3-1 > button:last-child:hover {
  background-color: rgb(214, 84, 61);
}

.detailsBlock-3 > p {
  border-top: 1px solid rgba(0, 0, 0, 0.205);
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  padding: 1vmax 0;
  color: rgba(0, 0, 0, 0.651);
  font: 400 1.2vmax ;
  margin: 1vmax 0;
}

.detailsBlock-4 {
  color: rgba(0, 0, 0, 0.897);
  font: 700 20px ;
}

.detailsBlock-4 > p {
  color: rgba(0, 0, 0, 0.864);
  font: 300 16px ;
}
.image-zoom > img {
  aspect-ratio: 11 / 11;
    object-fit: contain;
}
.submitReview {
  border: none;
  background-color: #996253;
  font: 600 0.99vmax ;
  border-radius: 20px;
  padding: 0.6vmax 2vmax;
  margin: 1vmax 0;
  color: white;
  cursor: pointer;
  transition: all 0.5s;
  outline: none;
}
.submitReview:hover {
  background-color: rgb(197, 68, 45);
  transform: scale(1.1);
}

.submitDialog {
  display: flex;
  flex-direction: column;
}
.submitDialogTextArea {
  border: 1px solid rgba(0, 0, 0, 0.082);
  margin: 1vmax 0;
  outline: none;
  padding: 1rem;
  font: 300 1rem ;
}

.reviewsHeading {
  color: #000000be;
  font: 500 1.4vmax ;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
  padding: 1vmax;
  width: 20vmax;
  margin: auto;
  margin-bottom: 4vmax;
}
.reviews {
  display: flex;
  overflow: auto;
}

.reviewCard {
  flex: none;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.226);
  border: 1px solid rgba(56, 56, 56, 0.116);
  width: 30vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vmax;
  padding: 3vmax;
}
.reviewCard > img {
  width: 5vmax;
}
.reviewCard > p {
  color: rgba(0, 0, 0, 0.836);
  font: 600 0.9vmax ;
}
.reviewCardComment {
  color: rgba(0, 0, 0, 0.445);
  font: 300 0.8vmax cursive;
}

.noReviews {
  font: 400 1.3vmax ;
  text-align: center;
  color: rgba(0, 0, 0, 0.548);
}

.back-btn {
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
  margin-top: 3vmax;
}
.back-btn > button {
  display: flex;
  height: 3em;
  width: 100px;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee4b;
  border-radius: 3px;
  letter-spacing: 1px;
  transition: all 0.2s linear;
  cursor: pointer;
  background: transparent;
  border: 1px solid black;
}

.back-btn > button > svg {
  margin-right: 5px;
  margin-left: 5px;
  font-size: 20px;
  transition: all 0.4s ease-in;
}

.back-btn > button:hover > svg {
  font-size: 1.2em;
  transform: translateX(-5px);
}

.back-btn > button:hover {
  box-shadow: 9px 9px 33px #d1d1d1, -9px -9px 33px #ffffff;
  transform: translateY(-2px);
}


.thumbs-wrapper > ul {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .ProductDetails {
    flex-direction: column;
    height: unset;
  }

  .ProductDetails > div:last-child {
    align-items: center;
  }

  .detailsBlock-1 > h2 {
    font-size: 2.8vmax;
    text-align: center;
  }

  .detailsBlock-1 > p {
    text-align: center;
    font-size: 1vmax;
  }

  .detailsBlock-2 {
    justify-content: center;
  }
  .detailsBlock-2 > span {
    font-size: 1.5vmax;
  }

  .detailsBlock-3 > h1 {
    font: 700 3vmax ;
    text-align: center;
  }

  .detailsBlock-3-1 {
    flex-direction: column;
  }

  .detailsBlock-3-1-1 {
    padding: 2vmax 0;
  }
  .detailsBlock-3-1-1 > button {
    padding: 1.2vmax;
    width: 4vmax;
  }

  .detailsBlock-3-1-1 > input {
    padding: 1.5vmax;
    width: 3vmax;
    font: 400 1.8vmax ;
  }

  .detailsBlock-3-1 > button:last-child {
    font: 500 1.7vmax ;
    padding: 1.5vmax;
    width: 20vmax;
    margin: 3vmax 0;
  }

  .detailsBlock-3 > p {
    padding: 2.5vmax 0;
    text-align: center;
    font: 400 2vmax ;
  }

  .detailsBlock-4 {
    font: 500 2.5vmax ;
  }

  .detailsBlock-4 > p {
    font: 300 1.8vmax ;
  }

  .submitReview {
    font: 500 1.7vmax ;
    padding: 1.5vmax;
    width: 20vmax;
    margin: 3vmax 0;
  }

  .reviewCard > p {
    font: 600 3vw ;
  }
  .reviewCardComment {
    font: 300 5vw cursive;
  }
}
.carousel-container {
  position: relative;
  z-index: 1; /* Ensure the container is on the correct layer */
}


.product-view-image {
  max-width: 300px;
  margin: 25px;
}

.product-view-image img {
  max-width: 100%;
}

.loupe {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px solid black;
  box-shadow: 5px 5px 12px black;
  background: rgba(0, 0, 0, 0.25);
  cursor: crosshair;
  overflow: hidden;
  display: none;
}

.loupe img {
  position: absolute;
}
.title {
  margin-bottom: 5vh;
}
.card {
  margin: auto;
  max-width: 950px;
  width: 90%;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 1rem;
  border: transparent;
}

.items-cart-div {
  height: 290px;
  overflow: auto;
}
@media (max-width: 767px) {
  .card {
    margin: 3vh auto;
  }
}
.cart {
  background-color: #fff;
  padding: 4vh 5vh;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
}
@media (max-width: 767px) {
  .cart {
    padding: 4vh;
    border-bottom-left-radius: unset;
    border-top-right-radius: 1rem;
  }
}
.summary {
  background-color: #ddd;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 4vh;
  color: rgb(65, 65, 65);
}
@media (max-width: 767px) {
  .summary {
    border-top-right-radius: unset;
    border-bottom-left-radius: 1rem;
  }
}
.summary .col-2 {
  padding: 0;
}
.summary .col-10 {
  padding: 0;
}
.row {
  margin: 0;
}
.title b {
  font-size: 1.5rem;
}
.main {
  margin: 0;
  padding: 2vh 0;
  width: 100%;
}
.cart > .col-2,
.col {
  padding: 0 1vh;
}

.close {
  margin-left: auto;
  font-size: 0.7rem;
}
.col-2 > img {
  width: 3.5rem;
}
.back-to-shop {
  margin-top: 4.5rem;
  cursor: pointer;
}

select {
  border: 1px solid rgba(0, 0, 0, 0.137);
  padding: 1.5vh 1vh;
  margin-bottom: 4vh;
  outline: none;
  width: 100%;
  background-color: rgb(247, 247, 247);
}
input {
  border: 1px solid rgba(0, 0, 0, 0.137);
  padding: 1vh;
  margin-bottom: 4vh;
  outline: none;
  width: 100%;
  background-color: rgb(247, 247, 247);
}
input:focus::-webkit-input-placeholder {
  color: transparent;
}
.btn {
  background-color: #000;
  border-color: #000;
  color: white;
  width: 100%;
  font-size: 0.7rem;
  margin-top: 4vh;
  padding: 1vh;
  border-radius: 0;
}
.btn:focus {
  box-shadow: none;
  outline: none;
  box-shadow: none;
  color: white;
  -webkit-box-shadow: none;
  -webkit-user-select: none;
  transition: none;
}
.btn:hover {
  color: white;
}

#code {
  background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.253),
      rgba(255, 255, 255, 0.185)
    ),
    url("https://img.icons8.com/small/16/000000/long-arrow-right.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
}

.mt-50 {
  margin-top: 50px;
}

.card-empty {
  margin-bottom: 30px;
  border: 0;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}

.card-empty .card-header {
  background-color: transparent;
  border-bottom: none;
  padding: 24px;
  border-bottom: 1px solid #f6f7fb;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card .card-body-empty {
  padding: 30px;
  background-color: transparent;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #4466f2 !important;
  border-color: #4466f2 !important;
}
h5 {
  margin-top: 4vh;
}
hr {
  margin-top: 1.25rem;
}
form {
  padding: 2vh 0;
}
a {
  padding: 0 1vh;
}

.fancy {
  margin-top: 20px !important;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 1.25em 2em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  -webkit-user-select: none;
          user-select: none;
  font-size: 13px;
}

.fancy::before {
  content: " ";
  width: 1.5625rem;
  height: 2px;
  background: black;
  top: 50%;
  left: 1.5em;
  position: absolute;
  transform: translateY(-50%);
  transform-origin: center;
  transition: background 0.3s linear, width 0.3s linear;
}

.fancy .text {
  font-size: 1.125em;
  line-height: 1.33333em;
  padding-left: 2em;
  display: block;
  text-align: left;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
}

.fancy .top-key {
  height: 2px;
  width: 1.5625rem;
  top: -2px;
  left: 0.625rem;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, left 0.3s ease-out;
}

.fancy .bottom-key-1 {
  height: 2px;
  width: 1.5625rem;
  right: 1.875rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.fancy .bottom-key-2 {
  height: 2px;
  width: 0.625rem;
  right: 0.625rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.fancy:hover {
  color: white;
  background: #996253;
}

.fancy:hover::before {
  width: 0.9375rem;
  background: white;
}

.fancy:hover .text {
  color: white;
  padding-left: 1.5em;
}

.fancy:hover .top-key {
  left: -2px;
  width: 0px;
}

.fancy:hover .bottom-key-1,
.fancy:hover .bottom-key-2 {
  right: 0;
  width: 0;
}

:root {
  --bodybg: #dcdefe;
  --primary-color: #996253;
  --grey: #d6d6d6;
  --placeholder: #969696;
  --white: #fff;
  --text: #333;
  --slider-bg: #eff3ff;
  --login-cta-hover: #9962539e;
}

.login-container {
  display: flex;
  max-width: 1122px;

  margin: auto;
  width: 100%;
  min-width: 320px;
}

.login-container .logo svg {
  height: 40px;
  width: 40px;
  fill: var(--primary-color);
}

.login-container .login-form {
  width: 50%;
  box-sizing: border-box;
  padding: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
}

.login-container .login-form .login-form-inner {
  max-width: 380px;
  width: 95%;
}

.login-container .login-form .google-login-button .google-icon svg {
  height: 20px;
  display: flex;
  margin-right: 10px;
}

.login-container .login-form .google-login-button {
  color: var(--text);
  border: 1px solid var(--grey);
  margin: 40px 0 20px;
}

.login-container .login-form .sign-in-seperator {
  text-align: center;
  position: relative;
  margin: 30px 0 20px;
}

.login-container .login-form .sign-in-seperator span {
  z-index: 1;
  position: relative;
  padding: 0 10px;
  font-size: 15px;
}

.login-container .login-form .login-form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.login-container .login-form .login-form-group label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text);
  margin-bottom: 10px;
}

.login-container .login-form .login-form-group input {
  padding: 13px 20px;
  box-sizing: border-box;
  border: 1px solid var(--grey);
  border-radius: 50px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: var(--text);
  transition: linear 0.2s;
}

.login-container .login-form .login-form-group input:focus {
  outline: none;
  border: 1px solid var(--primary-color);
}

.login-container
  .login-form
  .login-form-group
  input::-webkit-input-placeholder {
  color: var(--placeholder);
  font-weight: 300;
  font-size: 14px;
}

.login-container .login-form .login-form-group.single-row {
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5px;
}

/* custom checkbox */
.login-container .login-form .custom-check input[type="checkbox"] {
  height: 23px;
  width: 23px;
  margin: 0;
  padding: 0;
  opacity: 1;
  -webkit-appearance: none;
          appearance: none;
  border: 2px solid var(--primary-color);
  border-radius: 3px;
  background: var(--white);
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.login-container .login-form .custom-check input[type="checkbox"]:checked {
  border: 2px solid var(--primary-color);
  background: var(--primary-color);
}

.login-container
  .login-form
  .custom-check
  input[type="checkbox"]:checked:before,
.login-container
  .login-form
  .custom-check
  input[type="checkbox"]:checked:after {
  content: "";
  position: absolute;
  height: 2px;
  background: var(--white);
}

.login-container
  .login-form
  .custom-check
  input[type="checkbox"]:checked:before {
  width: 8px;
  top: 11px;
  left: 2px;
  transform: rotate(44deg);
}

.login-container
  .login-form
  .custom-check
  input[type="checkbox"]:checked:after {
  width: 14px;
  top: 8px;
  left: 5px;
  transform: rotate(-55deg);
}

.login-container .login-form .custom-check input[type="checkbox"]:focus {
  outline: none;
}

.login-container .login-form .custom-check {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container .login-form .custom-check label {
  margin: 0;
  color: var(--text);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.login-container .login-form .link {
  color: var(--primary-color);
  font-weight: 700;
  text-decoration: none;
  font-size: 14px;
}

.login-container .login-form .link:hover {
  text-decoration: underline;
}

.login-container .login-form .login-cta {
  color: var(--white);
  text-decoration: none;
  border: 1px solid var(--primary-color);
  margin: 25px 0 35px;
  background: var(--primary-color);
}

.login-container .login-form .login-cta:hover {
  background: var(--login-cta-hover);
}

.login-container .onboarding {
  flex: 1 1;
  display: none;
  width: 50%;
}

.login-container .login-form .login-form-group label .required-star {
  color: var(--primary-color);
  font-size: 18px;
  line-height: 10px;
}

.login-container .rounded-button {
  cursor: pointer;
  display: flex;
  width: 100%;
  text-decoration: none;
  border-radius: 50px;
  padding: 13px 20px;
  box-sizing: border-box;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  transition: linear 0.2s;
}

.login-container .rounded-button:hover {
  box-shadow: 0px 0px 4px 0px var(--grey);
}

.login-container .body-text {
  font-size: 14px;
  font-weight: 500;
  color: var(--text);
}

.login-container .onboarding .swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.login-container .onboarding .swiper-slide {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: var(--text);
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container .onboarding .swiper-pagination-bullet-active {
  background-color: var(--primary-color);
}

.login-container .onboarding .swiper-slide {
  flex-direction: column;
  display: flex;
}
.swiper-wrapper {
  padding: 20px;
}
.login-container .onboarding .swiper-slide .slide-image img {
  width: 100%;
  height: 585px;
}
.login-container .onboarding .slide-content {
  width: 60%;
}

.login-container .onboarding .slide-content h2 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
}

.login-container .onboarding .slide-content p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 30px;
}

.login-container .login-form .login-form-inner h1 {
  margin-bottom: 20px;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .login-container .onboarding {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .login-container {
    height: 100vh;
  }
}

@media screen and (width: 768px) {
  .login-container .onboarding {
    order: 0;
  }
  .login-container .login-form {
    order: 1;
  }
  .login-container {
    height: 100vh;
  }
}

@media screen and (max-width: 420px) {
  .login-container .login-form {
    padding: 20px;
  }
  .login-container .login-form-group {
    margin-bottom: 16px;
  }
  .login-container {
    margin: 0;
  }
}

:root {
  --bodybg: #dcdefe;
  --primary-color: #996253;
  --grey: #d6d6d6;
  --placeholder: #969696;
  --white: #fff;
  --text: #333;
  --slider-bg: #eff3ff;
  --register-cta-hover: #9962539e;
}

.register-container {
  display: flex;
  max-width: 1122px;

  margin: auto;
  width: 100%;
  min-width: 320px;
}

.register-container .logo svg {
  height: 40px;
  width: 40px;
  fill: var(--primary-color);
}

.register-container .register-form {
  width: 50%;
  box-sizing: border-box;
  padding: 10px 45px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
}

.register-container .register-form .register-form-inner {
  max-width: 380px;
  width: 95%;
}

.register-container .register-form .google-register-button .google-icon svg {
  height: 20px;
  display: flex;
  margin-right: 10px;
}

.register-container .register-form .google-register-button {
  color: var(--text);
  border: 1px solid var(--grey);
  margin: 40px 0 20px;
}

.register-container .register-form .sign-in-seperator {
  text-align: center;
  position: relative;
  margin: 30px 0 20px;
}

.register-container .register-form .sign-in-seperator span {
  z-index: 1;
  position: relative;
  padding: 0 10px;
  font-size: 15px;
}

.register-container .register-form .register-form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}

.register-container .register-form .register-form-group label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text);
  margin-bottom: 5px;
}

.register-container .register-form .register-form-group input {
  padding: 13px 20px;
  box-sizing: border-box;
  border: 1px solid var(--grey);
  border-radius: 50px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: var(--text);
  transition: linear 0.2s;
}

.register-container .register-form .register-form-group input:focus {
  outline: none;
  border: 1px solid var(--primary-color);
}

.register-container
  .register-form
  .register-form-group
  input::-webkit-input-placeholder {
  color: var(--placeholder);
  font-weight: 300;
  font-size: 14px;
}

.register-container .register-form .register-form-group.single-row {
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5px;
}

/* custom checkbox */
.register-container .register-form .custom-check input[type="checkbox"] {
  height: 23px;
  width: 23px;
  margin: 0;
  padding: 0;
  opacity: 1;
  -webkit-appearance: none;
          appearance: none;
  border: 2px solid var(--primary-color);
  border-radius: 3px;
  background: var(--white);
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.register-container
  .register-form
  .custom-check
  input[type="checkbox"]:checked {
  border: 2px solid var(--primary-color);
  background: var(--primary-color);
}

.register-container
  .register-form
  .custom-check
  input[type="checkbox"]:checked:before,
.register-container
  .register-form
  .custom-check
  input[type="checkbox"]:checked:after {
  content: "";
  position: absolute;
  height: 2px;
  background: var(--white);
}

.register-container
  .register-form
  .custom-check
  input[type="checkbox"]:checked:before {
  width: 8px;
  top: 11px;
  left: 2px;
  transform: rotate(44deg);
}

.register-container
  .register-form
  .custom-check
  input[type="checkbox"]:checked:after {
  width: 14px;
  top: 8px;
  left: 5px;
  transform: rotate(-55deg);
}

.register-container .register-form .custom-check input[type="checkbox"]:focus {
  outline: none;
}

.register-container .register-form .custom-check {
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-container .register-form .custom-check label {
  margin: 0;
  color: var(--text);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.register-container .register-form .link {
  color: var(--primary-color);
  font-weight: 700;
  text-decoration: none;
  font-size: 14px;
}

.register-container .register-form .link:hover {
  text-decoration: underline;
}

.register-container .register-form .register-cta {
  color: var(--white);
  text-decoration: none;
  border: 1px solid var(--primary-color);
  margin: 25px 0 35px;
  background: var(--primary-color);
}

.register-container .register-form .register-cta:hover {
  background: var(--register-cta-hover);
}

.register-container .onboarding {
  flex: 1 1;
  display: none;
  width: 50%;
}

.register-container .register-form .register-form-group label .required-star {
  color: var(--primary-color);
  font-size: 18px;
  line-height: 10px;
}

.register-container .rounded-button {
  cursor: pointer;
  display: flex;
  width: 100%;
  text-decoration: none;
  border-radius: 50px;
  padding: 13px 20px;
  box-sizing: border-box;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  transition: linear 0.2s;
}

.register-container .rounded-button:hover {
  box-shadow: 0px 0px 4px 0px var(--grey);
}

.register-container .body-text {
  font-size: 14px;
  font-weight: 500;
  color: var(--text);
}

.register-container .onboarding .swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.register-container .onboarding .swiper-slide {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: var(--text);
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-container .onboarding .swiper-pagination-bullet-active {
  background-color: var(--primary-color);
}

.register-container .onboarding .swiper-slide {
  flex-direction: column;
  display: flex;
}
.swiper-wrapper {
  padding: 20px;
}
.register-container .onboarding .swiper-slide .slide-image img {
  width: 100%;
  height: 585px;
}
.register-container .onboarding .slide-content {
  width: 60%;
}

.register-container .onboarding .slide-content h2 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
}

.register-container .onboarding .slide-content p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 30px;
}

@media screen and (min-width: 768px) {
  .register-container .onboarding {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .register-container {
    height: 100vh;
  }
}

@media screen and (width: 768px) {
  .register-container .onboarding {
    order: 0;
  }
  .register-container .register-form {
    order: 1;
  }
  .register-container {
    height: 100vh;
  }
}

@media screen and (max-width: 420px) {
  .register-container .register-form {
    padding: 20px;
  }
  .register-container .register-form-group {
    margin-bottom: 16px;
  }
  .register-container {
    margin: 0;
  }
}

ul,
li {
  margin: 0;
  list-style-type: none;
}
input {
  outline: none;
  border: none;
}
textarea {
  outline: none;
  border: none;
}
textarea:focus,
input:focus {
  border-color: transparent !important;
}
input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}
input::-webkit-input-placeholder {
  color: #adadad;
}
input:-moz-placeholder {
  color: #adadad;
}
input::-moz-placeholder {
  color: #adadad;
}
input:-ms-input-placeholder {
  color: #adadad;
}
textarea::-webkit-input-placeholder {
  color: #adadad;
}
textarea:-moz-placeholder {
  color: #adadad;
}
textarea::-moz-placeholder {
  color: #adadad;
}
textarea:-ms-input-placeholder {
  color: #adadad;
}
.contact-us-btn > button {
  outline: none !important;
  border: none;
  background: 0 0;
}
.contact-us-btn > button:hover {
  cursor: pointer;
}
iframe {
  border: none !important;
}
.txt1 {
  font-family: Poppins-Regular;
  font-size: 18px;
  line-height: 1.2;
  color: #fff;
}
.txt2 {
  font-family: Poppins-Regular;
  font-size: 15px;
  line-height: 1.6;
  color: #999;
}
.txt3 {
  font-family: Poppins-Regular;
  font-size: 15px;
  line-height: 1.6;
  color: #00ad5f;
}
.size1 {
  width: 355px;
  max-width: 100%;
}
.size2 {
  width: calc(100% - 43px);
}
.container-contact100 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f2f2f2;
}
.wrap-contact100 {
  width: 1120px;
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row-reverse;
}
.contact100-more {
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  padding: 30px 15px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.contact100-more::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
}
.address-pra {
  display: flex;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 47px;
}
.contact100-form {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  padding: 56px 55px 63px;
}
.contact100-form-title {
  width: 100%;
  display: block;
  font-family: Poppins-Regular;
  font-size: 30px;
  color: #333;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 33px;
}
.wrap-input100 {
  width: 100%;
  position: relative;
  border: 1px solid #e6e6e6;
}
.rs1-wrap-input100,
.rs2-wrap-input100 {
  width: 50%;
}
.rs2-wrap-input100 {
  border-left: none;
}
.label-input100 {
  font-family: Poppins-Regular;
  font-size: 12px;
  color: #555;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 55px;
  border: 1px solid #e6e6e6;
  border-bottom: none;
  padding: 10px 25px;
  margin-top: 15px;
  margin-bottom: 0;
}
.input100 {
  display: block;
  width: 100%;
  background: 0 0;
  font-family: Poppins-Regular;
  font-size: 18px;
  color: #666;
  line-height: 1.2;
  padding: 0 25px;
}
input.input100 {
}
textarea.input100 {
  min-height: 139px;
  padding-top: 19px;
  padding-bottom: 15px;
}
.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #00ad5f;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
  transform: scaleX(1.1) scaleY(1.3);
}
.input100:focus + .focus-input100 {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.container-contact100-form-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 23px;
}
.contact100-form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 200px;
  height: 50px;
  border-radius: 2px;
  background: #00ad5f;
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.4s;
}
.contact100-form-btn:hover {
  background: #333;
}
@media (max-width: 992px) {
  .contact100-form {
    width: 60%;
    padding: 56px 30px 63px;
  }
  .contact100-more {
    width: 40%;
  }
}
@media (max-width: 768px) {
  .contact100-form {
    width: 100%;
  }
  .contact100-more {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .contact100-form {
    padding: 56px 15px 63px;
  }
  .rs1-wrap-input100,
  .rs2-wrap-input100 {
    width: 100%;
  }
  .rs2-wrap-input100 {
    border-left: 1px solid #e6e6e6;
    border-top: none;
  }
}
.validate-input {
  position: relative;
}
.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  transform: translateY(-50%);
  right: 2px;
  pointer-events: none;
  font-family: Poppins-Regular;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s;
}
.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}
.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

.about-us-content {
  font-size: 20px;
  padding: 30px;
  text-transform: capitalize;
  margin: 0px 60px 120px 60px;
}
.about-us-image {
  width: 100%;
  height: 700px;
  object-fit: fill;
  background-position: center;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.checkOutPage {
  font-family: "Poppins", sans-serif;
  height: 100vh;
  width: 70%;
  margin: 0px auto;
  padding: 50px 0px 0px;
  color: #4e5150;
}
.checkOutPage header {
  height: 5%;
  margin-bottom: 30px;
}
.checkOutPage header > h3 {
  font-size: 25px;
  color: #4e5150;
  font-weight: 500;
}
.checkOutPage main {
  height: 85%;
  display: flex;
  grid-column-gap: 100px;
  column-gap: 100px;
}
.checkOutPage main .checkout-form {
  width: 50%;
}
.checkOutPage main .checkout-form form h6 {
  font-size: 12px;
  font-weight: 500;
}
.checkOutPage main .checkout-form form .form-control-check {
  margin: 10px 0px;
  position: relative;
}
.checkOutPage
  main
  .checkout-form
  form
  .form-control-check
  label:not([for="checkout-checkbox"]) {
  display: block;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 2px;
}
.checkOutPage
  main
  .checkout-form
  form
  .form-control-check
  input:not([type="checkbox"]) {
  width: 100%;
  padding: 10px 10px 10px 40px;
  border-radius: 10px;
  outline: none;
  border: 0.2px solid #4e5150 85;
  font-size: 12px;
  font-weight: 700;
}
.checkOutPage
  main
  .checkout-form
  form
  .form-control-check
  input:not([type="checkbox"])::placeholder {
  font-size: 10px;
  font-weight: 500;
}
.checkOutPage
  main
  .checkout-form
  form
  .form-control-check
  label[for="checkout-checkbox"] {
  font-size: 9px;
  font-weight: 500;
  line-height: 10px;
}
.checkOutPage main .checkout-form form .form-control-check > div {
  position: relative;
}
.checkOutPage main .checkout-form form .form-control-check > div span.fa {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(15px, -50%);
}
.checkOutPage main .checkout-form form .form-group {
  display: flex;
  grid-column-gap: 25px;
  column-gap: 25px;
}
.checkOutPage main .checkout-form form .checkbox-control {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  column-gap: 10px;
}
.checkOutPage main .checkout-form form .form-control-check-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.checkOutPage main .checkout-form form .form-control-check-btn button {
  padding: 10px 25px;
  font-size: 10px;
  color: #fff;
  background: #f2994a;
  border: 0;
  border-radius: 7px;
  letter-spacing: 0.5px;
  font-weight: 200;
  cursor: pointer;
}
.checkOutPage main .checkout-details {
  width: 40%;
}
.checkOutPage main .checkout-details .checkout-details-inner {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
}
.checkOutPage main .checkout-details .checkout-details-inner .checkout-lists {
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
  margin-bottom: 40px;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card {
  width: 100%;
  display: flex;
  grid-column-gap: 15px;
  column-gap: 15px;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-image {
  width: 35%;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-image
  img {
  width: 100%;
  object-fit: fill;
  border-radius: 10px;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-details {
  display: flex;
  flex-direction: column;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-details
  .card-name {
  font-size: 12px;
  font-weight: 500;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-details
  .card-price {
  font-size: 10px;
  font-weight: 500;
  color: #f2994a;
  margin-top: 5px;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-details
  .card-price
  span {
  color: #4e5150;
  text-decoration: line-through;
  margin-left: 10px;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-details
  .card-wheel {
  margin-top: 17px;
  border: 0.2px solid #4e5150 85;
  width: 90px;
  padding: 8px 8px;
  border-radius: 10px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-lists
  .card
  .card-details
  .card-wheel
  button {
  background: #e0e0e0;
  color: #828282;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 500;
}
.checkOutPage main .checkout-details .checkout-details-inner .checkout-shipping,
.checkOutPage main .checkout-details .checkout-details-inner .checkout-total {
  display: flex;
  font-size: 16px;
  padding: 5px 0px;
  border-top: 1px solid #bdbdbd;
  justify-content: space-between;
}
.checkOutPage
  main
  .checkout-details
  .checkout-details-inner
  .checkout-shipping
  p,
.checkOutPage main .checkout-details .checkout-details-inner .checkout-total p {
  font-size: 10px;
  font-weight: 500;
}
.checkOutPage footer {
  height: 5%;
  color: #bdbdbd;
  display: grid;
  place-items: center;
  font-size: 12px;
}
.checkOutPage footer a {
  text-decoration: none;
  color: inherit;
}
@media screen and (max-width: 1024px) {
  .checkOutPage {
    width: 80%;
  }
  .checkOutPage main {
    grid-column-gap: 70px;
    column-gap: 70px;
  }
}
@media screen and (max-width: 768px) {
  .checkOutPage {
    width: 92%;
  }
  .checkOutPage main {
    flex-direction: column-reverse;
    height: auto;
    margin-bottom: 50px;
  }
  .checkOutPage main .checkout-form {
    width: 100%;
    margin-top: 35px;
  }
  .checkOutPage main .checkout-details {
    width: 100%;
  }
  .checkOutPage footer {
    height: 10%;
  }
}




.thankyou-wrapper{
  width:100%;
  height:auto;
  margin:auto;
  /* background:#ffffff;  */
  padding:10px 0px 50px;
}
.thankyou-wrapper h1{
  font:100px Arial, Helvetica, sans-serif;
  text-align:center;
  color:#333333;
  padding:0px 10px 10px;
}
.thankyou-wrapper p{
  font:26px Arial, Helvetica, sans-serif;
  text-align:center;
  color:#333333;
  padding:5px 10px 10px;
}
.thankyou-wrapper a{
  font:26px Arial, Helvetica, sans-serif;
  text-align:center;
  color:#ffffff;
  display:block;
  text-decoration:none;
  width:250px;
  background:#E47425;
  margin:10px auto 0px;
  padding:15px 20px 15px;
  border-bottom:5px solid #F96700;
}
.thankyou-wrapper a:hover{
  font:26px Arial, Helvetica, sans-serif;
  text-align:center;
  color:#ffffff;
  display:block;
  text-decoration:none;
  width:250px;
  background:#F96700;
  margin:10px auto 0px;
  padding:15px 20px 15px;
  border-bottom:5px solid #F96700;
}


.search-box {
  padding: 50px auto;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 30px;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.form-control {
  border-radius: 20px !important;
  background-color: transparent;
  border-color: black;
}

.form-group {
  margin: 0px !important;
  padding-bottom: 1rem;
}

.custom-menu-col > button {
  cursor: pointer;
  width: 150px;
  margin: 10px;
  font-size: 18px;
  background-color: transparent;
  border-radius: 12px;
  color: rgb(5, 1, 1);
}

.custom-menu-col > button:hover {
  background-color: black;
  color: white;
  transition: 0.5s all;
}

.activeState > button {
  color: white;
  background-color: tomato;
}
.searchbar {
  font-size: 14px;
  font-family: arial, sans-serif;
  color: #202124;
  display: flex;
  z-index: 3;
  height: 44px;
  background: transparent;
  border: 1px solid black;
  box-shadow: none;
  border-radius: 24px;
  margin: 0 auto;
  width: auto;
  max-width: 224px;
}

.searchbar:hover {
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  border-color: rgba(223, 225, 229, 0);
}

.searchbar-wrapper {
  flex: 1;
  display: flex;
  padding: 5px 8px 0 14px;
}

.searchbar-left {
  font-size: 14px;
  font-family: arial, sans-serif;
  color: #202124;
  display: flex;
  align-items: center;
  padding-right: 13px;
  margin-top: -5px;
}

.search-icon-wrapper {
  margin: auto;
}

.search-icon {
  margin-top: 3px;
  color: #9aa0a6;
  height: 20px;
  line-height: 20px;
  width: 20px;
}

.searchbar-icon {
  display: inline-block;
  fill: currentColor;
  height: 24px;
  line-height: 24px;
  position: relative;
  width: 24px;
}

.searchbar-center {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.searchbar-input-spacer {
  color: transparent;
  flex: 100%;
  white-space: pre;
  height: 34px;
  font-size: 16px;
}

.searchbar-input {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  outline: none;
  display: flex;
  flex: 100%;
  margin-top: -37px;
  height: 34px;
  font-size: 16px;
  max-width: 100%;
  width: 100%;
}
